@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  background-color: #FFF9E7;
  padding: 0 106px 0 94px;
  max-width: 1080px;
  margin: 0 auto;
  justify-content: space-between;
  font-family: Avenir;
  margin-top: 32px;
  border-radius: 8px;

  @include respond(mobile) {
    padding: 24px;
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 16px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    max-width: 346px;
    padding: 24px 0;

    .contentTextWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #2F3136;
      }

      @include respond(mobile) {
        align-items: center;
        text-align: center;
      }
    }

    a {
      display: inline-flex;
      align-items: center;
      width: 140px;
      height: 40px;
      padding: 9px 16px;
      color: #423DCF;
      border-radius: 8px;
      border: 1px solid #CDCECE;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      text-decoration: none;
      column-gap: 12px;
      background-color: white;

      @include respond(mobile) {
        display: flex;
        margin: 0 auto;
      }
    }
  }

  .imageWrapper {
    display: flex;
    align-items: flex-end;
    max-width: 292px;
    align-self: flex-end;

    @include respond(mobile) {
      align-self: auto;
    }
  }
}